const CustomOption = ({ value, label, all, filtered }) => {
  return (
    <div className="flex items-center justify-between">
      <div>{label}</div>
      <div>{` ${filtered}/${all}`}</div>
    </div>
  );
};

export default CustomOption;
