import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';

import { loadableSubscriptions } from '../../atoms/subscriptions';
import Table from '../../elements/Table/Table';
import useColumns from './useColumns';

const SubscriptionsGrid = ({}) => {
  const { t, i18n } = useTranslation(['common']);
  const [subscriptions] = useAtom(loadableSubscriptions);
  const columns = useColumns(t, i18n.language);

  return (
    <Table
      columns={columns}
      isLoading={subscriptions.state === 'loading'}
      height="h-[200px]"
      data={subscriptions?.data ? Object.entries(subscriptions?.data) : []}
    />
  );
};

export default SubscriptionsGrid;
