import { useMemo } from 'react';
const useColumns = (t) => {
  const columns = useMemo(
    () => [
      {
        id: 'index',
        header: '',
        align: 'center',
        size: 50,
        cell: ({ row }) => row.index + 1
      },
      {
        align: 'center',
        header: t('name'),
        size: 270,
        cell: (info) => info.getValue() || '--'
      },
      {
        align: 'center',
        header: t('email'),
        size: 270,

        cell: ({ row: { original } }) => {
          return original.email || '--';
        }
      }
    ],
    [t]
  );

  return columns;
};

export default useColumns;
