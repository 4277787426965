import { atom } from 'jotai';

import { fetchEndoscopes } from '../api/endoscopes';

export const endoscopesAtom = atom({});
export const endoscopesStatusAtom = atom({ status: 'idle' });

export const loadEndoscopesAtom = atom(
  {}, // The initial state.
  async (get, set, videoId) => {
    try {
      set(endoscopesStatusAtom, { status: 'loading' });
      const data = await fetchEndoscopes(videoId);

      set(endoscopesAtom, data);
      set(endoscopesStatusAtom, { status: 'hasData' });
      return data;
    } catch (error) {
      console.error('Error fetching videos:', error);
      set(endoscopesStatusAtom, { status: 'error' });
    }
  }
);

export const endoscopeHistoryAtom = atom([]);
export const loadingHistoryAtom = atom(false);
