import { API_URL } from '../helpers/constants';
import { getUser } from '../utils/session';
import { generateUrl } from '../utils/helpers';

export async function getVideoList(selectedCompany, tableFilters = {}) {
  try {
    const user = getUser();
    let url = `${API_URL}/videos_fast/${selectedCompany}`;
    const queryParams = generateUrl(tableFilters);

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${user.access_token}`
      },
      body: JSON.stringify(tableFilters)
    });

    if (response.ok) {
      const json = await response.json();

      if (Array.isArray(json)) {
        return {
          videos: []
        };
      }
      return {
        ...json
      };
    }
  } catch (e) {
    console.log('Error', e);
  }
}

export async function getVideoFilters(selectedCompany, videoFilters) {
  try {
    const user = getUser();
    let url = `${API_URL}/videos_fast/${selectedCompany}/filter_options`;

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${user.access_token}`
      },
      body: JSON.stringify(videoFilters)
    });

    if (response.ok) {
      const json = await response.json();

      return json;
    }
  } catch (e) {
    console.log('Error', e);
  }
}

export async function registerVideo(data) {
  const user = getUser();

  try {
    const response = await fetch(API_URL + '/register_video', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${user.access_token}`
      },
      body: JSON.stringify(data)
    });
    const json = await response.json();
    if (response.ok) {
      return json;
    } else {
      console.log(json);
      throw new Error(json.detail);
    }
  } catch (e) {
    console.log('fetch users error', e);
    throw new Error(e);
  }
}

export async function uploadVideo(video_id, data) {
  try {
    const user = getUser();

    const formData = new FormData();
    formData.append('file', data);
    const response = await fetch(API_URL + `/upload_video/${video_id}`, {
      method: 'POST',
      headers: {
        accept: 'application/json',
        Authorization: `Bearer ${user.access_token}`
        // 'Content-Type': 'multipart/form-data'
      },
      body: formData
    });
    const json = await response.json();
    if (response.ok) {
      return json;
    } else {
      console.log(json);
      throw new Error(json.detail);
    }
  } catch (e) {
    console.log('fetch users error', e);
    throw new Error(e);
  }
}
