import { useCallback } from 'react';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import debounce from 'lodash/debounce';
import { IconButton } from '@material-tailwind/react';
import {
  MdOutlineKeyboardDoubleArrowLeft,
  MdOutlineKeyboardDoubleArrowRight,
  MdOutlineKeyboardArrowRight,
  MdOutlineKeyboardArrowLeft
} from 'react-icons/md';

import { paginationAtom } from '../../atoms/app';
import { pageSizes } from '../../helpers/constants';

const Pagination = ({ totalPages = 0 }) => {
  const [pagination, setPagination] = useAtom(paginationAtom);
  const { pageIndex = 1, pageSize } = pagination;
  const { t } = useTranslation(['common', 'buttons']);

  const setPageSize = useCallback((e) => {
    setPagination({ pageIndex: 1, pageSize: Number(e.target.value) });
  }, []);

  const handlePageChange = useCallback((page) => {
    setPagination((prev) => ({ ...prev, pageIndex: page }));
  }, []);

  const handleNextPage = useCallback((type) => {
    const step = type === 'prev' ? -1 : 1;
    setPagination((prev) => ({ ...prev, pageIndex: prev.pageIndex + step }));
  }, []);

  const debouncePageChange = useCallback(
    debounce((e) => {
      const page = Number(e.target.value);

      if (page > 0 && page <= totalPages && page !== pageIndex) {
        console.log('page0', page);
        handlePageChange(page);
      }
    }, 500),
    [totalPages, pageIndex]
  );

  return (
    <div className="flex justify-between gap-4 px-4 py-2 flex-wrap">
      <div className="flex items-center gap-3">
        <IconButton
          size="sm"
          variant="outlined"
          onClick={() => handlePageChange(1)}
          disabled={pageIndex === 1}
        >
          <MdOutlineKeyboardDoubleArrowLeft className="h-4 w-4" />
        </IconButton>
        <IconButton
          size="sm"
          variant="outlined"
          onClick={() => handleNextPage('prev')}
          disabled={pageIndex === 1}
        >
          <MdOutlineKeyboardArrowLeft className="h-4 w-4" />
        </IconButton>
        <div className="flex items-center gap-1">
          {`${t('common:page')} ${pageIndex} ${t('common:of').toLowerCase()} ${totalPages || '--'}`}
        </div>
        <IconButton
          size="sm"
          variant="outlined"
          onClick={handleNextPage}
          disabled={!totalPages || pageIndex === totalPages}
        >
          <MdOutlineKeyboardArrowRight className="h-4 w-4" />
        </IconButton>
        <IconButton
          size="sm"
          variant="outlined"
          onClick={() => handlePageChange(totalPages)}
          disabled={!totalPages || pageIndex === totalPages}
        >
          <MdOutlineKeyboardDoubleArrowRight className="h-4 w-4" />
        </IconButton>
      </div>

      <div>
        <span className="mr-2">{t('common:goToPage')}</span>
        <input
          type="number"
          min={'1'}
          max={totalPages}
          // defaultValue={pageIndex}
          onChange={debouncePageChange}
          className="border p-1 rounded w-16"
        />
      </div>

      <div className="flex items-center gap-1 ">
        {`${t('buttons:resultsPerPage')}:`}
        <select value={pageSize} onChange={setPageSize}>
          {pageSizes.map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              {` ${pageSize}  `}
            </option>
          ))}
        </select>
      </div>
      {/* <div>
        Showing {pageIndex * pageSize} of 
        {totalPages && totalPages * pageSize}
        Rows
      </div> */}
    </div>
  );
};

export default Pagination;
