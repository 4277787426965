import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';

import { loadableEmailsAtom } from '../../atoms/company';
import Table from '../../elements/Table/Table';
import useColumns from './useColumns';

const EmailsGrid = ({}) => {
  const { t } = useTranslation(['common']);
  const [emails] = useAtom(loadableEmailsAtom);
  const columns = useColumns(t);

  return (
    <Table
      columns={columns}
      isLoading={emails.state === 'loading'}
      height="h-[35vh]"
      data={emails?.data ? emails?.data : []}
    />
  );
};

export default EmailsGrid;
