import isEqual from 'lodash.isequal';
import { format, endOfDay } from 'date-fns';

export function countReports(history, videoId) {
  const videoData = history.find((c) => c.video_id === videoId);

  const reportCount = videoData.reports.filter((c) => !c.draft).length;

  return reportCount;
}

export function delayApicall(api, time, params) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(api(params));
    }, time);
  });
}

export function maxContaminationsInSec(contaminations, seconds, contaminationsQuantatity) {
  const max = seconds.reduce((acc, curr) => {
    const length = Object.keys(contaminations[curr]).length;
    return length > acc ? length : acc;
  }, contaminationsQuantatity);
  return max;
}

export function convertToBlob(pdf) {
  const byteCharacters = atob(pdf);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], { type: 'application/pdf' });
  return blob;
}

// Hack to open blob in new tab in Safario iOS
export function createNewTabBlob(loaderText) {
  const winHtml = `<!DOCTYPE html><html><head><title>Downloading report, please wait</title><head><body><h3>${loaderText}</h3></body></html>`;
  const winUrl = URL.createObjectURL(new Blob([winHtml], { type: 'text/html' }));
  return winUrl;
}

export function downloadPDF(pdf, name, isTouchDevice) {
  const blob = convertToBlob(pdf);

  const downloadLink = document.createElement('a');
  downloadLink.style.display = 'none'; // Hide the link
  const fileName = name || 'file.pdf';
  downloadLink.href = window.URL.createObjectURL(blob);
  downloadLink.download = fileName;
  downloadLink.target = '_blank';

  if (isTouchDevice) {
    return window.open(downloadLink.href, '_blank');
  }

  document.body.appendChild(downloadLink); // Required for Firefox
  downloadLink.click();
  document.body.removeChild(downloadLink); // Cleanup
  window.URL.revokeObjectURL(downloadLink.href); // Cleanup
}

export function binarySearch(arr, target) {
  let start = 0;
  let end = arr.length - 1;

  while (end >= start) {
    let mid = Math.floor((start + end) / 2);
    if (arr[mid] == target) {
      return mid;
    }
    if (arr[mid] > target) {
      end = mid - 1;
    } else {
      start = mid + 1;
    }
  }

  if (end < 0) {
    end = 0;
  }
  return end;
}

export const getMediaTime = () =>
  new Promise((resolve) => {
    const video = document.getElementsByTagName('video')[0];

    if (!video) return;
    video.requestVideoFrameCallback((now, metadata) => {
      resolve(metadata.mediaTime);
    });
  });

export const jumpNextFrame = async (direction, firstMediaTime) => {
  const video = document.getElementsByTagName('video')[0];
  if (!video) return;

  if (
    (video.duration === video.currentTime || video.duration - video.currentTime < 0.04) &&
    direction !== 'back'
  ) {
    return;
  }
  // force rerender
  video.currentTime += 0.0001;
  video.currentTime -= 0.0001;

  // get current frame time
  // const firstMediaTime = await getMediaTime();

  if (!firstMediaTime && direction === 'back') {
    return;
  }
  const jump = direction === 'back' ? -0.01 : 0.01;
  for (;;) {
    // now adjust video's current time until actual frame time changes
    video.currentTime += jump;

    if ((await getMediaTime()) !== firstMediaTime) break;
  }
};

export function convertRemToPixels(rem) {
  return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
}

export const filterUrl = (url, toRemove) => {
  return url
    .split('/')
    .filter((c) => c !== toRemove)
    .join('/');
};

export const generateUrl = (filters) => {
  const {
    manualReport,
    aiReport,
    fromDate,
    toDate,
    reporterUser,
    recorderUser,
    selectedProducer,
    selectedModel,
    selectedSerial,
    contaminationPath,
    ownerId,
    deviceType,
    selectedDevice
  } = filters;
  let queryParams = [];

  if (manualReport) queryParams.push(`rep_man=true`);
  if (aiReport) queryParams.push(`rep_ai=true`);
  if (fromDate) queryParams.push(`timeFrom=${format(fromDate, 'yyyy-MM-dd HH:mm')}`);
  if (toDate) queryParams.push(`timeTo=${format(endOfDay(toDate), 'yyyy-MM-dd HH:mm')}`);
  if (reporterUser) queryParams.push(`gen_by=${reporterUser}`);
  if (recorderUser) queryParams.push(`recorded_by=${recorderUser}`);
  if (selectedProducer) queryParams.push(`endosc_prod=${selectedProducer}`);
  if (selectedModel) queryParams.push(`endosc_model=${selectedModel}`);
  if (selectedSerial) queryParams.push(`endosc_serial=${selectedSerial}`);
  if (deviceType) queryParams.push(`device_type=${deviceType}`);
  if (contaminationPath) {
    let allContaminationPath = 'cont_spec=' + contaminationPath;
    queryParams.push(allContaminationPath);
  }
  if (ownerId) queryParams.push(`customer=${ownerId}`);
  if (selectedDevice) queryParams.push(`hw_id=${selectedDevice}`);

  return queryParams;
};

export const formOptions = (allOptions, filteredOptions = {}) => {
  const arr = [];

  if (!allOptions) {
    return arr;
  }

  for (let key in allOptions) {
    arr.push({
      value: key,
      label: key,
      all: allOptions[key],
      filtered: filteredOptions[key] || 0
    });
  }

  return arr;
};

export const formContaminationData = (frames, fps, domainMax, mod, speed) => {
  const data = {};
  if (!frames || !fps) {
    return data;
  }

  for (const [key, value] of Object.entries(frames)) {
    const sec = Math.floor(+key / +fps).toString();
    // Depending on record mode, either seconds or cm will be used
    const position = mod === 'Auto' ? Math.floor((sec * speed) / 10) : sec;
    const group = Math.floor(+position / domainMax).toString();

    value?.forEach((c) => {
      if (!data[group] || !data[group][position] || !data[group][position][c.label]) {
        if (c.label) {
          const item = { [c.label]: { ...c, frame: key } };
          const groupSec = data[group] && data[group][position] ? data[group][position] : {};
          data[group] = { ...data[group], [position]: { ...groupSec, ...item } };
        }
      }
    });
  }

  return data;
};

export const getVideoId = () => {
  return window?.location?.pathname?.split('/').pop();
};

export const getVideoType = () => {
  const pathName = window?.location?.pathname?.toString();

  return pathName.split('/')[2];
};

export const formUserAccess = (data) => {
  return Object.entries(data)
    .filter((c) => c[0] !== 'parentCompany' && c[1])
    .map((c) => c[0]);
};

export const generateSelectOptions = (data) => {
  const processedArray = data?.map((originalObject) => {
    const modifiedObject = {
      value: originalObject.id,
      label: originalObject.name
    };
    return modifiedObject;
  });
  return processedArray;
};

export const setSelectValue = (value, data, isMulti) => {
  if (!data || !value) {
    return null;
  }

  if (data.length > 0) {
    if (data[0].options) {
      const option = data.find((c) => c.options.some((k) => isEqual(k.value, value)));

      return option?.options?.filter((c) => isEqual(c.value, value));
    }
    if (isMulti) {
      return data.filter((c) => value.includes(c.value));
    }
  }

  return data.filter((c) => isEqual(c.value, value))[0] || null;
};

export const formDefaultCompanies = (access) => {
  const defaultCompanies = {};
  Object.entries(access).forEach((c) => {
    const item = c[1];
    defaultCompanies[item.id] = item.access;
  });
  return defaultCompanies;
};

export const normalizeCompanyData = (inputObject) => {
  const transformedArray = Object.keys(inputObject).map((key) => ({
    id: key,
    ...inputObject[key]
  }));
  return transformedArray;
};

export const cutLanguageCode = (lang) => {
  if (!lang) {
    return '';
  }
  return lang.split('-')[0];
};

export const reportStatus = (status) => {
  switch (status) {
    case 0:
      return 'G';
    case 1:
      return 'C';
    case 2:
      return 'D';
    case 3:
      return 'D+C';
    default:
      return '';
  }
};

export const colorToshowBylabel = (label, options) => {
  const option = options.find((option) => option.label === label);
  return option?.color || 'red';
};

export const optionByLabel = (label, options) => {
  const option = options.find((option) => option.label === label);
  return option;
};
