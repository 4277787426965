import { Fragment, useMemo } from 'react';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { RiDraftLine } from 'react-icons/ri';

import StatusCircle from '../../components/StatusCircle';
import { formatMonthDayYear, formatMonthDayYearWithHours } from '../../utils/date';
import { reportStatus } from '../../utils/helpers';
import useReportDownload from '../../hooks/useReportDownload';
import { paginationAtom } from '../../atoms/app';

const useColumns = (companyType, handleRowClick) => {
  const { t, i18n } = useTranslation('common');
  const [pagination, setPagination] = useAtom(paginationAtom);
  const { pageIndex, pageSize } = pagination;
  const [handleReportClick] = useReportDownload();

  const columns = [
    {
      id: 'index',
      header: '',
      align: 'center',
      size: 50,
      cell: ({ row }) => row.index + 1 + (pageIndex - 1) * pageSize
    },
    {
      name: 'record_dt',
      header: t('recordDate'),
      cell: ({
        row: {
          original: { record_dt, id }
        }
      }) => (
        <a onClick={(e) => handleRowClick(e, id)} href="#" className="underline text-indigo-500">
          {formatMonthDayYearWithHours(record_dt, i18n.language)}
        </a>
      )
    },
    {
      header: t('serial'),

      cell: ({
        row: {
          original: { endoscope }
        }
      }) => endoscope.serial
    },
    {
      header: t('endoscope'),

      cell: ({
        row: {
          original: { endoscope }
        }
      }) => (
        <div className="flex flex-col">
          <span>{endoscope.producer}</span>
          <span> {endoscope.model}</span>
        </div>
      )
    },
    {
      header: t('reports'),
      size: 200,
      minSize: 180,
      cell: ({
        row: {
          original: { report }
        }
      }) => (
        <>
          {report.map((c) => (
            <Fragment key={c.id}>
              {c.draft ? (
                <div className="flex items-center mb-1" key={c.id}>
                  <RiDraftLine className="mr-1" />
                  <span>{` - ${c.generated_by_name}`}</span>
                </div>
              ) : (
                <div
                  key={c.id}
                  onClick={() => handleReportClick(c.id)}
                  className="flex items-center mb-1 underline text-indigo-500 cursor-pointer"
                >
                  <StatusCircle customStyle="mr-1" status={c.status} />
                  <span>{`(${reportStatus(c.status)}) - ${c.generated_by_name}`}</span>
                </div>
              )}
            </Fragment>
          ))}
        </>
      )
    },
    {
      header: t('deviceType'),
      minSize: 150,
      cell: ({
        row: {
          original: { endoscope }
        }
      }) => endoscope.device_type
    },
    { accessorKey: 'record_by_name', header: t('recordedBy') },

    {
      name: 'reportDate',
      header: t('reportDate'),
      cell: ({
        row: {
          original: { report }
        }
      }) => (
        <>
          {report.map((c, i) => (
            <div key={c.id} className="mb-1">
              {formatMonthDayYear(c.generated_dt, i18n.language)}
            </div>
          ))}
        </>
      )
    }
  ];

  if (companyType === 1) {
    columns.splice(6, 0, {
      header: t('customer'),
      cell: ({
        row: {
          original: { endoscope }
        }
      }) => endoscope.owner_name
    });
  }

  const formedColumns = useMemo(() => columns, [companyType, t, pageIndex, pageSize]);

  return formedColumns;
};

export default useColumns;
