import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useAtom } from 'jotai';
import { Button } from '@material-tailwind/react';
import { useNavigate, useParams } from 'react-router-dom';

import {
  videosAtom,
  loadVideosAtom,
  videosStatusAtom,
  endoscopeVideoIdAtom
} from '../../atoms/video';
import { paginationAtom } from '../../atoms/app';
import { endoscopeHistoryAtom, endoscopesAtom } from '../../atoms/endoscopes';

const VideoSwitcher = () => {
  const navigate = useNavigate();
  const { t } = useTranslation(['buttons']);
  const [videosStatus] = useAtom(videosStatusAtom);
  const [endoscopeVideoId, setEndoscopeVideoId] = useAtom(endoscopeVideoIdAtom);
  const [videosData] = useAtom(videosAtom);
  const [history, setHistory] = useAtom(endoscopeHistoryAtom);
  const [endoscopes, setEndoscopes] = useAtom(endoscopesAtom);
  const [pagination, setPagination] = useAtom(paginationAtom);

  const [, loadVideos] = useAtom(loadVideosAtom);
  const { page_size, page, total_pages, videos } = videosData;
  const { video_id } = useParams();

  const position = videos?.findIndex((video) => video.id === video_id);

  const handleNextPage = useCallback((type) => {
    const step = type === 'prev' ? -1 : 1;
    setPagination((prev) => ({ ...prev, pageIndex: prev.pageIndex + step }));
  }, []);

  const videosOnLoadCallback = useCallback(
    (id) => {
      if (endoscopeVideoId) {
        setEndoscopeVideoId(null);
      }
      setHistory([]);
      setEndoscopes({});
      navigate(`/video/${id}`, { replace: true });
    },
    [endoscopeVideoId]
  );

  const handleSwitch = (type) => {
    const step = type === 'prev' ? -1 : 1;
    if (position + 1 === page_size && page < total_pages && type !== 'prev') {
      handleNextPage();
      return loadVideos(videosOnLoadCallback);
    }
    if (position === 0 && type === 'prev') {
      handleNextPage('prev');
      return loadVideos(videosOnLoadCallback, 'prev');
    }
    const nextVideo = videos[position + step]?.id;
    return videosOnLoadCallback(nextVideo);
  };

  return (
    <>
      {videos?.length > 0 && (
        <div>
          <Button
            className="rounded-2xl my-2 mr-4"
            disabled={
              videosStatus.status === 'loading' || position < 0 || (position === 0 && page === 1)
            }
            onClick={() => handleSwitch('prev')}
            color="light-blue"
            variant="gradient"
          >
            {t('buttons:previousVideo')}
          </Button>
          <Button
            className="rounded-2xl my-2"
            disabled={
              videosStatus.status === 'loading' ||
              position < 0 ||
              (position + 1 === videos.length && page === total_pages)
            }
            onClick={handleSwitch}
            color="light-blue"
            variant="gradient"
          >
            {t('buttons:nextVideo')}
          </Button>
        </div>
      )}
    </>
  );
};

export default VideoSwitcher;
