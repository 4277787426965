import { useEffect } from 'react';
import { useAtom } from 'jotai';
import { useNavigate } from 'react-router-dom';

import Header from '../../components/Header/Header';
import Table from '../../elements/Table/Table';
import { companiesDataAtom, currentCompanyAtom } from '../../atoms/companies';
import useColumns from './useColumns';
import {
  videoTypeAtom,
  loadVideosAtom,
  videosAtom,
  videosStatusAtom,
  tableFiltersAtom
} from '../../atoms/video';
import { paginationAtom } from '../../atoms/app';
import Filters from './Filters';

import '../../index.css';
import '../../styles/datePicker.css';

function VideoList() {
  const [selectedCompany, setSelectedCompany] = useAtom(currentCompanyAtom);
  const [companiesData, setCompaniesData] = useAtom(companiesDataAtom);
  const [tableFilters, setTableFilters] = useAtom(tableFiltersAtom);
  const [pagination, setPagination] = useAtom(paginationAtom);
  const [videoType, setVideoType] = useAtom(videoTypeAtom);

  const [videos] = useAtom(videosAtom);
  const [videosStatus] = useAtom(videosStatusAtom);
  const [, loadVideos] = useAtom(loadVideosAtom);

  const currentCompanyData = companiesData?.find((c) => c.id === selectedCompany);

  const navigate = useNavigate();

  const handleRowClick = (e, data) => {
    e.stopPropagation();

    navigate(`/video/${data}`, { replace: false });
    if (videoType === 'wo') {
      setVideoType('wt');
    }
  };

  const columns = useColumns(currentCompanyData?.type, handleRowClick);

  const heightProp = `h-[74vh] lg:h-[80vh]`;
  const height = 'calc(100vh - 113px)';

  useEffect(() => {
    if (selectedCompany) {
      loadVideos();
    }
  }, [selectedCompany, pagination, tableFilters]);

  return (
    <>
      <Header></Header>
      <div className="grid grid-cols-10 border-t border-gray-200 dark:border-gray-600">
        <div style={{ height }} className={`col-span-3 xl:col-span-2`}>
          <Filters companyType={currentCompanyData?.type} />
        </div>
        <div className="p-4 col-span-7 xl:col-span-8">
          <Table
            withPagination={true}
            columns={columns}
            height={heightProp}
            isLoading={videosStatus.status === 'loading'}
            data={videos?.videos}
            totalPages={videos?.total_pages}
          />
        </div>
      </div>
    </>
  );
}

export default VideoList;
