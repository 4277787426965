import { useMemo } from 'react';
import { MdOutlineEdit, MdDeleteOutline } from 'react-icons/md';

const useColumns = (t, userRole, handleOpen) => {
  console.log('userRole', userRole);
  const columns = [
    {
      id: 'index',
      header: '',
      align: 'center',
      size: 50,
      cell: ({ row }) => row.index + 1
    },
    {
      accessorKey: 'firstName',
      size: 200,
      header: t('firstName')
    },
    {
      accessorKey: 'lastName',
      size: 200,
      header: t('lastName')
    },
    {
      accessorKey: 'email',

      header: t('email')
    },
    {
      accessorKey: 'department',

      header: t('department')
    },
    {
      header: t('userType'),
      size: 200,
      cell: ({
        row: {
          original: { user_type }
        }
      }) => t(`${user_type}`)
    },
    {
      accessorKey: 'parent_company',
      header: t('parentCompany'),
      size: 200
    }
  ];

  if (userRole === 'system-admin') {
    columns.push({
      id: 'controls',
      header: '',
      minSize: 120,
      size: 120,
      cell: ({ row: { original } }) => {
        console.log('original', original);
        return (
          <>
            {original.user_type === 'ui_user' && (
              <div className="flex justify-evenly">
                <div
                  onClick={() => handleOpen('access', original.user_id)}
                  className="rounded-lg cursor-pointer hover:bg-gray-300 group/edit invisible group-hover/item:visible py-2 px-4"
                >
                  <MdOutlineEdit className="h-4 w-4" />
                </div>

                <div
                  onClick={() => handleOpen('remove', original.user_id)}
                  className="rounded-lg cursor-pointer  hover:bg-gray-300 group/edit invisible group-hover/item:visible py-2 px-4"
                >
                  <MdDeleteOutline className="h-4 w-4" />
                </div>
              </div>
            )}
          </>
        );
      }
    });
  }

  const formedColumns = useMemo(() => columns, [userRole, t]);
  console.log('formedColumns', formedColumns);
  return formedColumns;
};

export default useColumns;
