import { useEffect, useState } from 'react';
import { useAtom } from 'jotai';
import { SimpleTreeView, TreeItem2 } from '@mui/x-tree-view';
import { MdOutlineEdit, MdDeleteOutline } from 'react-icons/md';
import { RiDraftLine } from 'react-icons/ri';
import { useTranslation } from 'react-i18next';
import cls from 'classnames';

import { fetchEndoscopeHistory } from '../../api/endoscopes';
import { deleteDraft } from '../../api/reports';
import { getReport } from '../../api/reports';
import {
  endoscopesAtom,
  endoscopesStatusAtom,
  endoscopeHistoryAtom,
  loadingHistoryAtom
} from '../../atoms/endoscopes';
import { loadingReportsAtom } from '../../atoms/reports';
import {
  videoTypeAtom,
  framesAtom,
  reportModeAtom,
  reportFramesAtom,
  endoscopeVideoIdAtom
} from '../../atoms/video';

import { formatMonthDayYear, formatMonthDayYearWithHours } from '../../utils/date';
import { getVideoId } from '../../utils/helpers';
import StatusCircle from '../StatusCircle';
import Loader from '../Loader';

const EndoscopeHistory = ({}) => {
  const { t, i18n } = useTranslation('buttons', 'common');

  const [endoscopes, setEndoscopes] = useAtom(endoscopesAtom);
  const [endoscopesStatus] = useAtom(endoscopesStatusAtom);
  const [videoType, setVideoType] = useAtom(videoTypeAtom);
  const [history, setHistory] = useAtom(endoscopeHistoryAtom);
  const [frames, setFrames] = useAtom(framesAtom);
  const [reportFrames, setReportFrames] = useAtom(reportFramesAtom);
  const [reportMode, setReportMode] = useAtom(reportModeAtom);
  const [isHistoryLoading, setHistotyIsLoading] = useAtom(loadingHistoryAtom);
  const [isReportsLoading, setIsReportsLoading] = useAtom(loadingReportsAtom);
  const [endoscopeVideoId, setEndoscopeVideoId] = useAtom(endoscopeVideoIdAtom);

  const endoscopeId = endoscopes?.endoscope?.id;
  const isLoading = endoscopesStatus.status === 'loading';

  const videoIdFromUrl = getVideoId();
  const videoId = endoscopeVideoId || videoIdFromUrl;

  const [expandedItems, setExpandedItems] = useState([videoId]);

  const handleReportClick = async (id, draft) => {
    if (id === reportFrames.id) {
      return;
    }
    try {
      setIsReportsLoading(true);
      const data = await getReport(id);
      setIsReportsLoading(false);
      setReportFrames({ id, frames: data.frames, draft });
    } catch (error) {
      setIsReportsLoading(false);
      console.log('Get Report Error', error);
    }
  };

  const handleNodeClick = (e, nextVideoId, reports) => {
    if (videoId !== nextVideoId) {
      // navigate(`/video/${videoId}`, { replace: true });
      setEndoscopeVideoId(nextVideoId);
      if (videoType === 'wo') {
        setVideoType('wt');
      }
    }
    if (reports.length && reports[0].id !== reportFrames.id) {
      handleReportClick(reports[0].id, reports[0].draft);
    }
  };

  const handleEditClick = async (e, id, draft) => {
    try {
      e.stopPropagation();
      let frames = reportFrames.frames;
      if (id !== reportFrames.id) {
        setIsReportsLoading(true);
        const res = await getReport(id);
        frames = res.frames;
        setReportFrames({ id, frames: frames, draft });
        setIsReportsLoading(false);
      }

      const dataKeys = Object.keys(frames);

      if (dataKeys.length) {
        setReportMode(true);
        setFrames({ ...frames });
      }
    } catch (error) {
      console.log('Get Report Error', error);
      setIsReportsLoading(false);
    }
  };
  const handleDeleteClick = async (e, id) => {
    try {
      e.stopPropagation();
      setHistotyIsLoading(true);
      await deleteDraft(id);
      downloadEndoscopeHistory(endoscopeId);
    } catch (error) {
      setHistotyIsLoading(false);
      console.log('Get Report Error', error);
    }
  };

  const handleExpandedItemsChange = (e, itemId, isExpanded) => {
    if (isExpanded) {
      setExpandedItems([itemId]);
    } else {
      setExpandedItems([]);
    }
  };

  const downloadEndoscopeHistory = async (id, preloadReport) => {
    try {
      setHistotyIsLoading(true);
      const res = await fetchEndoscopeHistory({ endoscopeId: id });

      setHistory(res);

      if (preloadReport) {
        const videoHistory = res.find((c) => c.video_id === videoId);

        if (videoHistory && videoHistory.reports.length > 0) {
          const report = videoHistory.reports[0];

          report && handleReportClick(report.id, report.draft);
        }
      }
      setHistotyIsLoading(false);
    } catch (error) {
      setHistotyIsLoading(false);
      console.log('Error', error);
    }
  };

  useEffect(() => {
    endoscopeId && downloadEndoscopeHistory(endoscopeId, true);
  }, [endoscopeId, videoIdFromUrl]);

  useEffect(() => {
    if ((videoId, expandedItems[0] !== videoId)) {
      setExpandedItems([videoId]);
    }
  }, [videoId]);

  useEffect(() => {
    return () => {
      setHistory([]);
      setEndoscopes({});
      setEndoscopeVideoId(null);
    };
  }, []);

  return (
    <>
      {!reportMode && (
        <fieldset className="h-[200px] overflow-y-auto  mt-4 p-6 border-2 rounded-4xl	border-neutral-100 border-opacity-100">
          <legend className="text-blue-main font-semibold	px-1">
            {t('common:endoscopeHistory')}
          </legend>
          {(isHistoryLoading || (isLoading && !endoscopeId)) && <Loader heightFull />}
          {!isHistoryLoading && history?.length > 0 && (
            <SimpleTreeView
              aria-label="file system navigator"
              expandedItems={expandedItems}
              onItemExpansionToggle={handleExpandedItemsChange}
            >
              {history?.map(({ video_id, recorded_date, reports }) => (
                <TreeItem2
                  slotProps={{
                    content: {
                      className: 'rounded-4xl'
                    }
                    // root: {
                    //   className: 'bg-blue-500'
                    // }
                  }}
                  className="text-xl"
                  key={video_id}
                  itemId={video_id}
                  label={
                    <span
                      className={cls('', {
                        'font-bold': videoId === video_id
                      })}
                    >
                      {formatMonthDayYear(recorded_date, i18n.language)}
                    </span>
                  }
                  onClick={(e) => handleNodeClick(e, video_id, reports)}
                >
                  {reports.map(({ id, generated_by_name, draft, status, generated_dt }) => (
                    <li
                      key={id}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleReportClick(id, draft);
                      }}
                      className={cls(
                        'flex items-center cursor-pointer rounded-[4px] py-1 mb-1 first:mt-1 group/item hover:bg-gray-100',
                        {
                          'bg-gray-100': reportFrames.id === id
                        }
                      )}
                    >
                      <div className="flex items-center w-[60%] ml-7">
                        <div className="mr-2 text-base">{generated_by_name || '--'}</div>
                        <span className="mr-2 text-sm">{`(${formatMonthDayYearWithHours(
                          generated_dt,
                          i18n.language
                        )})`}</span>
                        {draft ? (
                          <RiDraftLine className="h-3.5 w-3.5" />
                        ) : (
                          <StatusCircle status={status} />
                        )}
                      </div>
                      <div className="flex w-[40%] justify-end">
                        {draft && (
                          <div
                            onClick={(e) => handleDeleteClick(e, id)}
                            className="flex items-center text-base rounded-lg mx-3 py-1 px-5 group/edit invisible hover:bg-gray-200 group-hover/item:visible"
                          >
                            <MdDeleteOutline />
                            <span className="ml-1 text-nowrap">{t('deleteDraft')}</span>
                          </div>
                        )}

                        <div
                          onClick={(e) => handleEditClick(e, id, draft)}
                          className="flex items-center text-base rounded-lg mx-3 py-1 px-5 group/edit invisible hover:bg-gray-200 group-hover/item:visible"
                        >
                          <MdOutlineEdit />
                          <span className="ml-1">{t('edit')}</span>
                        </div>
                      </div>
                    </li>
                  ))}
                </TreeItem2>
              ))}
            </SimpleTreeView>
          )}
        </fieldset>
      )}
    </>
  );
};

export default EndoscopeHistory;
