import { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useReactTable, getCoreRowModel, flexRender } from '@tanstack/react-table';
import cls from 'classnames';

import Loader from '../../components/Loader';
import TableBody from './TableBody';
import Pagination from './Pagination';

const Table = ({
  data,
  columns,
  enableColumnResizing = true,
  isLoading = false,
  withPagination = false,
  height = 'auto',
  totalPages
}) => {
  const { t } = useTranslation(['common']);

  const table = useReactTable({
    enableColumnResizing,
    data,
    columns,
    defaultColumn: {
      minSize: 50,
      maxSize: 800
    },
    columnResizeMode: 'onChange',
    getCoreRowModel: getCoreRowModel()
  });

  const headerRef = useRef(null);
  const headerHeight = headerRef.current?.offsetHeight || 0;

  const columnSizeVars = useMemo(() => {
    const headers = table.getFlatHeaders();

    const colSizes = {};
    for (let i = 0; i < headers.length; i++) {
      const header = headers[i];
      colSizes[`--header-${header.id}-size`] = header.getSize();
      colSizes[`--col-${header.column.id}-size`] = header.column.getSize();
    }

    return colSizes;
  }, [table.getState().columnSizingInfo, table.getState().columnSizing]);

  return (
    <>
      <div
        className={cls(
          `block relative w-full ${height} overflow-x-auto overflow-y-auto border border-gray-200`
        )}
      >
        <table
          {...{
            className: 'text-sm min-w-full',
            style: {
              ...columnSizeVars, //Define column sizes on the <table> element
              width: table.getTotalSize()
            }
          }}
        >
          <thead ref={headerRef} className="text-gray-700">
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id} className="border-b border-gray-200">
                {headerGroup.headers.map((header, i) => {
                  const columnDef = header.column.columnDef;
                  return (
                    <th
                      key={header.id}
                      className={cls('relative border-l border-gray-200', {
                        'border-l-0': i === 0
                      })}
                      style={{
                        width: `calc(var(--header-${header?.id}-size) * 1px)`
                      }}
                    >
                      <div
                        className={cls(`flex`, `justify-${columnDef.align} && ${columnDef.align}`)}
                      >
                        {header.isPlaceholder
                          ? null
                          : flexRender(header.column.columnDef.header, header.getContext())}
                      </div>

                      <div
                        {...{
                          // onDoubleClick: () => header.column.resetSize(),
                          onMouseDown: header.getResizeHandler(),
                          onTouchStart: header.getResizeHandler(),
                          className: `resizer ${header.column.getIsResizing() ? 'isResizing' : ''}`
                        }}
                      />
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          {!isLoading && data?.length > 0 && <TableBody table={table} />}
        </table>

        {!isLoading && data?.length === 0 && (
          <div
            style={{
              height: `calc(100% - ${headerHeight}px)`
            }}
            className={`w-full flex items-center justify-center`}
          >
            <div className="text-sm text-gray-700">{t('common:noRecords')}</div>
          </div>
        )}

        {isLoading && (
          <div
            className="flex justify-center"
            style={{
              height: `calc(100% - ${headerHeight}px)`
            }}
          >
            <Loader height="" />
          </div>
        )}
      </div>
      {withPagination && <Pagination totalPages={totalPages} table={table} />}
    </>
  );
};

export default Table;
