import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';

import { modalAtom, appUserAtom, isLoadingAtom } from '../../atoms/app';
import { loadableUsersAtom } from '../../atoms/users';
import { currentCompanyAtom } from '../../atoms/companies';
import Table from '../../elements/Table/Table';
import { isUserAdmin } from '../../utils/session';
import useColumns from './useColumns';

const UsersGrid = ({}) => {
  const { t } = useTranslation(['common']);
  const [users] = useAtom(loadableUsersAtom);
  const [modal, setModal] = useAtom(modalAtom);
  const [isLoading, setIsLoading] = useAtom(isLoadingAtom);
  const [selectedCompany, setSelectedCompany] = useAtom(currentCompanyAtom);
  const isAdmin = isUserAdmin();
  const [appUser, setAppUser] = useAtom(appUserAtom);
  const { user_parent_company, user_role } = appUser || {};

  const handleOpen = (name, userId) => {
    return setModal({ name, isOpen: true, data: { userId } });
  };

  const columns = useColumns(t, user_role, handleOpen);

  const heightProp = !isAdmin && user_parent_company === selectedCompany ? 'h-[55vh]' : 'h-[70vh]';

  return (
    <>
      <Table
        height={heightProp}
        columns={columns}
        isLoading={users.state === 'loading' || isLoading}
        data={users?.data ? users?.data : []}
      />
    </>
  );
};

export default UsersGrid;
