import { memo } from 'react';
import { flexRender } from '@tanstack/react-table';
import cls from 'classnames';

function TableBody({ table }) {
  const rowModel = table.getRowModel();
  const rows = rowModel.rows || [];

  return (
    <tbody className="text-gray-700">
      {rows?.length > 0 &&
        rows.map((row) => (
          <tr className="border-b border-gray-200 group/item" key={row.id}>
            {row.getVisibleCells().map((cell) => {
              const columnDef = cell.column.columnDef;

              return (
                <td
                  key={cell.id}
                  style={{
                    width: `calc(var(--col-${cell.column.id}-size) * 1px)`
                  }}
                >
                  <div
                    className={cls('flex flex-col', {
                      'items-center': columnDef.align === 'center',
                      'flex-col': columnDef.flexDirection === 'column'
                    })}
                  >
                    {flexRender(columnDef.cell, cell.getContext())}
                  </div>
                </td>
              );
            })}
          </tr>
        ))}
    </tbody>
  );
}

const MemoizedTableBody = memo(
  TableBody,
  (prev, next) => prev.table.options.data === next.table.options.data
);

export default MemoizedTableBody;
