import Select from 'react-select';
import { useAtom } from 'jotai';
import { useLocation } from 'react-router-dom';

import { paginationAtom } from '../atoms/app';
import { filtersOptionsAtom, tableFiltersAtom, filtersInitialState } from '../atoms/video';
import { companiesDataAtom, currentCompanyAtom } from '../atoms/companies';
import { generateSelectOptions, setSelectValue } from '../utils/helpers';

const CompanySelector = () => {
  const location = useLocation();
  const [selectedCompany, setSelectedCompany] = useAtom(currentCompanyAtom);
  const [pagination, setPagination] = useAtom(paginationAtom);
  const [filters, setFilters] = useAtom(filtersOptionsAtom);
  const [tableFilters, setTableFilters] = useAtom(tableFiltersAtom);
  const [companiesData] = useAtom(companiesDataAtom);
  const options = generateSelectOptions(companiesData);

  const handleCompanysSelect = ({ value }) => {
    if (pagination.pageIndex !== 1) {
      setPagination({ ...pagination, pageIndex: 1 });
    }
    setFilters(filtersInitialState);
    setTableFilters(filtersInitialState);
    setSelectedCompany(value);
  };

  return (
    <Select
      isDisabled={location.pathname.includes('/video/')}
      name="Parent Company"
      placeholder="Please select company"
      options={options}
      isSearchable={false}
      value={setSelectValue(selectedCompany, options)}
      onChange={handleCompanysSelect}
      classNames={{
        singleValue: () => 'w-20 md:w-48',
        control: () => '[&&]:rounded-xl [&&]:cursor-pointer font-medium',
        option: () => '[&&]:cursor-pointer'
        // menu: () => '[&&]:z-50'
      }}
    />
  );
};

export default CompanySelector;
