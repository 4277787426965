import { API_URL } from '../helpers/constants';

export async function registerUser(data) {
  try {
    const response = await fetch(API_URL + '/register_user', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    });
    const json = await response.json();
    if (response.ok) {
      return json;
    } else {
      throw new Error(json.detail);
    }
  } catch (e) {
    console.log('fetch users error', e);
    throw new Error(e);
  }
}

export async function registerUserInExistingCompany(data) {
  try {
    const response = await fetch(API_URL + '/register_user_in_existing_company', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    });
    const json = await response.json();
    if (response.ok) {
      return json;
    } else {
      console.log(json);
      throw new Error(json.detail);
    }
  } catch (e) {
    console.log('fetch users error', e);
    throw new Error(e);
  }
}
