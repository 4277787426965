import { API_URL } from '../helpers/constants';
import { getUser } from '../utils/session';

export async function getSubscriptions(currentCompany) {
  try {
    const user = getUser();

    const response = await fetch(`${API_URL}/subscriptions/${currentCompany}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${user.access_token}`
      }
    });

    if (response.ok) {
      const json = await response.json();
      return json;
    }
  } catch (e) {
    console.log('Error', e);
    throw new Error(e);
  }
}
export async function addSubscription(currentCompany, data) {
  try {
    const user = getUser();
    const response = await fetch(`${API_URL}/subscriptions/${currentCompany}/new`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${user.access_token}`
      },
      body: JSON.stringify(data)
    });

    if (response.ok) {
      const json = await response.json();
      return json;
    }
  } catch (e) {
    console.log('Error', e);
    throw new Error(e);
  }
}
