import { useState, useMemo } from 'react';
import { Button, Checkbox } from '@material-tailwind/react';
import ReactSelect from 'react-select';
import { useAtom } from 'jotai';
import DateTimePicker from 'react-datetime-picker';
import { useTranslation } from 'react-i18next';
import { startOfDay, endOfDay } from 'date-fns';
import isEqual from 'lodash.isequal';

import { paginationAtom } from '../../atoms/app';
import {
  loadableFilterOptionsAtom,
  filtersOptionsAtom,
  tableFiltersAtom,
  filtersInitialState
} from '../../atoms/video';

import { setSelectValue, formOptions } from '../../utils/helpers';
import { contaminationOptions } from '../../helpers/constants';
import CustomOption from './CustomOption';
import MultiValueLabel from './MultiValueLabel';

const Filters = ({ companyType }) => {
  const { t } = useTranslation('common');
  const [filters, setFilters] = useAtom(filtersOptionsAtom);
  const [pagination, setPagination] = useAtom(paginationAtom);
  const [tableFilters, setTableFilters] = useAtom(tableFiltersAtom);

  const [filterOptions] = useAtom(loadableFilterOptionsAtom);
  const filterOptionsData = filterOptions?.data || { all_options: {}, filtered_options: {} };
  const [selectedContamination, setSelectedContamination] = useState([]);

  const handleContaminationsSelect = (selectedOption) => {
    setSelectedContamination(selectedOption);
  };

  const areFiltersDefault =
    isEqual(filters, filtersInitialState) &&
    isEqual(tableFilters, filtersInitialState) &&
    !selectedContamination.length;
  const areFiltersNotChanged = isEqual(
    { ...filters, spec_cont: selectedContamination.map((item) => item.value) },
    tableFilters
  );
  const handleFiltersChange = (data, type) => {
    if (
      (!data && type !== 'rep_man' && type !== 'rep_ai') ||
      (Array.isArray(data) && data.length === 0)
    ) {
      const newFilters = { ...filters };
      delete newFilters[type];
      return setFilters({ ...newFilters });
    }
    setFilters({ ...filters, [type]: data });
  };

  const handleApplyFilters = () => {
    if (pagination.pageIndex !== 1) {
      setPagination({ ...pagination, pageIndex: 1 });
    }
    const newFilters = { ...filters, spec_cont: selectedContamination.map((item) => item.value) };

    setTableFilters(newFilters);
  };

  const handleResetFilters = () => {
    setSelectedContamination([]);
    setFilters(filtersInitialState);
    setTableFilters(filtersInitialState);
  };

  const recorderOptionsData = useMemo(
    () =>
      formOptions(
        filterOptionsData?.all_options?.recorded_by,
        filterOptionsData?.filtered_options?.recorded_by
      ),
    [filterOptionsData]
  );
  const reporterOptionsData = useMemo(
    () =>
      formOptions(
        filterOptionsData?.all_options?.generated_by,
        filterOptionsData?.filtered_options?.generated_by
      ),
    [filterOptionsData]
  );

  const deviceType = useMemo(
    () =>
      formOptions(
        filterOptionsData?.all_options?.device_type,
        filterOptionsData?.filtered_options?.device_type
      ),
    [filterOptionsData]
  );
  const producersOptionsData = useMemo(
    () =>
      formOptions(
        filterOptionsData?.all_options?.endosc_prod,
        filterOptionsData?.filtered_options?.endosc_prod
      ),
    [filterOptionsData]
  );

  const modelsOptionsData = useMemo(
    () =>
      formOptions(
        filterOptionsData?.all_options?.endosc_model,
        filterOptionsData?.filtered_options?.endosc_model
      ),
    [filterOptionsData]
  );
  const serialsOptionsData = useMemo(
    () =>
      formOptions(
        filterOptionsData?.all_options?.endosc_serial,
        filterOptionsData?.filtered_options?.endosc_serial
      ),
    [filterOptionsData]
  );
  const ownersOptionsData = useMemo(
    () =>
      formOptions(
        filterOptionsData?.all_options?.customer,
        filterOptionsData?.filtered_options?.customer
      ),
    [filterOptionsData]
  );

  const devices = useMemo(
    () =>
      formOptions(
        filterOptionsData?.all_options?.hw_id,
        filterOptionsData?.filtered_options?.hw_id
      ),
    [filterOptionsData]
  );

  return (
    <div className="pl-6 pr-4 py-4 h-full w-full overflow-y-auto">
      <div className="mb-2">
        <div className="pb-2">{t('common:from')}:</div>
        <DateTimePicker
          disabled={filterOptions.state === 'loading'}
          calendarClassName="rounded-2xl"
          format="MM dd, yyyy"
          disableClock={true}
          onChange={(data) => handleFiltersChange(data, 'time_from')}
          value={filters.time_from}
          minDate={startOfDay(new Date(filterOptionsData?.all_options?.time_from))}
        />
      </div>
      <div className="mb-2">
        <div className="pb-2">{t('common:to')}:</div>
        <DateTimePicker
          disabled={filterOptions.state === 'loading'}
          calendarClassName="rounded-2xl"
          format="MM dd, yyyy"
          disableClock={true}
          onChange={(data) => handleFiltersChange(data ? endOfDay(data) : data, 'time_to')}
          value={filters.time_to}
          minDate={new Date(filterOptionsData?.all_options?.time_from)}
        />
      </div>

      <div className="flex justify-between items-center">
        <div>{t('common:containManual')}</div>
        <Checkbox
          disabled={filterOptions.state === 'loading'}
          checked={filters.rep_man || false}
          onChange={(e) => handleFiltersChange(e.target.checked, 'rep_man')}
        />
      </div>
      <div className="flex justify-between items-center">
        <div>{t('common:containReports')}</div>
        <Checkbox
          disabled={filterOptions.state === 'loading'}
          checked={filters.rep_ai || false}
          onChange={(e) => handleFiltersChange(e.target.checked, 'rep_ai')}
        />
      </div>

      <div className="width-inner pb-2 pt-2">
        <ReactSelect
          classNames={{
            control: () => '[&&]:cursor-pointer font-medium',
            option: () => '[&&]:cursor-pointer'
          }}
          isClearable
          isLoading={filterOptions.state === 'loading'}
          isDisabled={filterOptions.state === 'loading'}
          hideSelectedOptions={false}
          isMulti
          formatOptionLabel={CustomOption}
          components={{ MultiValueLabel }}
          options={recorderOptionsData}
          value={setSelectValue(filters.recorded_by, recorderOptionsData, true)}
          onChange={(selectedOption) => {
            handleFiltersChange(
              selectedOption.map((c) => c.value),
              'recorded_by'
            );
          }}
          placeholder={t('common:userVideo')}
          className="w-full"
        ></ReactSelect>
      </div>
      <div className="">
        <div className="width-inner pb-2 pt-2">
          <ReactSelect
            classNames={{
              control: () => '[&&]:cursor-pointer font-medium',
              option: () => '[&&]:cursor-pointer'
            }}
            isClearable
            isLoading={filterOptions.state === 'loading'}
            isDisabled={filterOptions.state === 'loading'}
            hideSelectedOptions={false}
            isMulti
            formatOptionLabel={CustomOption}
            components={{ MultiValueLabel }}
            options={reporterOptionsData}
            value={setSelectValue(filters.generated_by, reporterOptionsData, true)}
            onChange={(selectedOption) =>
              handleFiltersChange(
                selectedOption.map((c) => c.value),
                'generated_by'
              )
            }
            placeholder={t('common:userReport')}
            className=""
          ></ReactSelect>
        </div>
      </div>

      <div className="">
        <div className="width-inner pb-2 pt-2">
          <ReactSelect
            classNames={{
              control: () => '[&&]:cursor-pointer font-medium',
              option: () => '[&&]:cursor-pointer'
            }}
            isClearable
            isLoading={filterOptions.state === 'loading'}
            isDisabled={filterOptions.state === 'loading'}
            hideSelectedOptions={false}
            isMulti
            formatOptionLabel={CustomOption}
            components={{ MultiValueLabel }}
            options={deviceType}
            value={setSelectValue(filters.device_type, deviceType, true)}
            onChange={(selectedOption) =>
              handleFiltersChange(
                selectedOption.map((c) => c.value),
                'device_type'
              )
            }
            placeholder={t('common:deviceType')}
            className=""
          ></ReactSelect>
        </div>
      </div>

      <div className="">
        <div className="width-inner pb-2 pt-2">
          <ReactSelect
            classNames={{
              control: () => '[&&]:cursor-pointer font-medium',
              option: () => '[&&]:cursor-pointer'
            }}
            isClearable
            isLoading={filterOptions.state === 'loading'}
            isDisabled={filterOptions.state === 'loading'}
            hideSelectedOptions={false}
            isMulti
            formatOptionLabel={CustomOption}
            components={{ MultiValueLabel }}
            options={producersOptionsData}
            value={setSelectValue(filters.endosc_prod, producersOptionsData, true)}
            onChange={(selectedOption) =>
              handleFiltersChange(
                selectedOption.map((c) => c.value),
                'endosc_prod'
              )
            }
            placeholder={t('common:oem')}
            className=""
          ></ReactSelect>
        </div>
      </div>

      <div className="">
        <div className="width-inner pb-2 pt-2">
          <ReactSelect
            classNames={{
              control: () => '[&&]:cursor-pointer font-medium',
              option: () => '[&&]:cursor-pointer'
            }}
            isClearable
            isLoading={filterOptions.state === 'loading'}
            isDisabled={filterOptions.state === 'loading'}
            hideSelectedOptions={false}
            isMulti
            formatOptionLabel={CustomOption}
            components={{ MultiValueLabel }}
            options={modelsOptionsData}
            value={setSelectValue(filters.endosc_model, modelsOptionsData, true)}
            onChange={(selectedOption) =>
              handleFiltersChange(
                selectedOption.map((c) => c.value),
                'endosc_model'
              )
            }
            placeholder={t('common:model')}
            className=""
          ></ReactSelect>
        </div>
      </div>

      <div className="">
        <div className="width-inner pb-2 pt-2">
          <ReactSelect
            classNames={{
              control: () => '[&&]:cursor-pointer font-medium',
              option: () => '[&&]:cursor-pointer'
            }}
            isClearable
            isLoading={filterOptions.state === 'loading'}
            isDisabled={filterOptions.state === 'loading'}
            hideSelectedOptions={false}
            isMulti
            formatOptionLabel={CustomOption}
            components={{ MultiValueLabel }}
            options={serialsOptionsData}
            value={setSelectValue(filters.endosc_serial, serialsOptionsData, true)}
            onChange={(selectedOption) =>
              handleFiltersChange(
                selectedOption.map((c) => c.value),
                'endosc_serial'
              )
            }
            placeholder={t('common:serial')}
            className="w-full"
          ></ReactSelect>
        </div>
      </div>

      <div className="">
        <div className="width-inner pb-2 pt-2">
          <ReactSelect
            classNames={{
              control: () => '[&&]:cursor-pointer font-medium',
              option: () => '[&&]:cursor-pointer'
            }}
            isMulti
            isLoading={filterOptions.state === 'loading'}
            isDisabled={filterOptions.state === 'loading'}
            hideSelectedOptions={false}
            options={contaminationOptions}
            value={selectedContamination}
            onChange={handleContaminationsSelect}
            placeholder={t('common:issue')}
            className="basic-multi-select "
          ></ReactSelect>
        </div>
      </div>

      {companyType === 1 && (
        <div className="">
          <div className="width-inner pb-2 pt-2">
            <ReactSelect
              classNames={{
                control: () => '[&&]:cursor-pointer font-medium',
                option: () => '[&&]:cursor-pointer'
              }}
              isClearable
              isLoading={filterOptions.state === 'loading'}
              isDisabled={filterOptions.state === 'loading'}
              hideSelectedOptions={false}
              isMulti
              formatOptionLabel={CustomOption}
              components={{ MultiValueLabel }}
              options={ownersOptionsData}
              value={setSelectValue(filters.customer, ownersOptionsData, true)}
              onChange={(selectedOption) =>
                handleFiltersChange(
                  selectedOption.map((c) => c.value),
                  'customer'
                )
              }
              placeholder={t('common:customer')}
              className="basic-multi-select "
            ></ReactSelect>
          </div>
        </div>
      )}

      <div className="">
        <div className="width-inner pb-2 pt-2">
          <ReactSelect
            classNames={{
              control: () => '[&&]:cursor-pointer font-medium',
              option: () => '[&&]:cursor-pointer'
            }}
            isClearable
            isLoading={filterOptions.state === 'loading'}
            isDisabled={filterOptions.state === 'loading'}
            hideSelectedOptions={false}
            isMulti
            formatOptionLabel={CustomOption}
            components={{ MultiValueLabel }}
            options={devices}
            value={setSelectValue(filters.hw_id, devices, true)}
            onChange={(selectedOption) =>
              handleFiltersChange(
                selectedOption.map((c) => c.value),
                'hw_id'
              )
            }
            placeholder={t('common:verifyAI')}
            className="basic-multi-select "
          ></ReactSelect>
        </div>
      </div>

      <div className="flex justify-between mt-4 mb-5">
        <Button
          className="px-4 py-2 2xl:py-3 2xl:px-6"
          disabled={filterOptions.state === 'loading' || areFiltersDefault}
          variant="gradient"
          color="red"
          onClick={handleResetFilters}
        >
          {t('buttons:reset')}
        </Button>
        <Button
          className="px-4 py-2 2xl:py-3 2xl:px-6"
          disabled={filterOptions.state === 'loading' || areFiltersNotChanged}
          variant="gradient"
          color="green"
          onClick={handleApplyFilters}
        >
          {t('buttons:applyFilters')}
        </Button>
      </div>
    </div>
  );
};

export default Filters;
