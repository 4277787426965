import { useMemo } from 'react';

const useColumns = (t) => {
  const columns = [
    {
      id: 'index',
      header: '',
      align: 'center',
      size: 50,
      cell: ({ row }) => row.index + 1
    },
    {
      accessorKey: 'ALIAS',
      header: t('alias')
    },
    {
      header: t('tz'),
      align: 'center',
      cell: ({
        row: {
          original: { TZ }
        }
      }) => <div className="text-center">{TZ > 0 ? `+${TZ}` : TZ}</div>
    },
    {
      accessorKey: 'HARDWARE_ID',
      size: 200,
      header: t('hwId')
    },
    {
      header: t('mode'),
      cell: ({
        row: {
          original: { MODE }
        }
      }) => (MODE === 0 ? t('automatic') : t('manual'))
    },
    {
      accessorKey: 'DEPARTMENT',
      header: t('department')
    },
    {
      accessorKey: 'PHONE',
      header: t('phone')
    },
    {
      accessorKey: 'EMAIL',
      header: t('email')
    }
  ];

  const formedColumns = useMemo(() => columns, [t]);

  return formedColumns;
};

export default useColumns;
