import { atom } from 'jotai';
import { loadable } from 'jotai/utils';

import { currentCompanyAtom } from './companies';
import { paginationAtom } from './app';

import { getVideoList, getVideoFilters } from '../api/video';

export const filtersInitialState = { rep_man: false, rep_ai: false, spec_cont: [] };

export const filtersOptionsAtom = atom(filtersInitialState);

export const tableFiltersAtom = atom(filtersInitialState);

export const endoscopeVideoIdAtom = atom(null);

export const videoTypeAtom = atom('wt');
export const videosAtom = atom({ videos: [] });
export const videosStatusAtom = atom({ status: 'idle' });

export const isVideoLoadingAtom = atom(false);

export const framesAtom = atom({});

export const frameAtom = atom(0);

export const mediaTimeAtom = atom(0);

export const currentTimeAtom = atom(0);

export const targetFrameAtom = atom();

export const uploadIdAtom = atom('');

export const reportFramesAtom = atom({ id: null, frames: {} });

export const reportModeAtom = atom(false);
export const isReportingAtom = atom(false);
export const durationAtom = atom(0);
export const isPausedAtom = atom(true);

export const loadVideosAtom = atom(
  null, // The initial state.
  async (get, set, callback, type) => {
    const currentCompany = get(currentCompanyAtom);
    const { pageIndex, pageSize } = get(paginationAtom);

    const tableFilters = get(tableFiltersAtom);
    const filters = {
      filters: tableFilters,
      page: pageIndex,
      page_size: pageSize
    };

    if (!currentCompany) {
      return { videos: [] };
    }

    try {
      set(videosStatusAtom, { status: 'loading' });
      const data = await getVideoList(currentCompany, filters);

      if (callback) {
        const id = type === 'prev' ? data.videos[data.videos.length - 1].id : data.videos[0].id;
        callback(id);
      }

      set(videosAtom, data);
      set(videosStatusAtom, { status: 'hasData' });
      return data;
    } catch (error) {
      console.error('Error fetching videos:', error);
      set(videosStatusAtom, { status: 'error' });
      // callback({ videos: [], error });
    }
  }
);

export const loadFilterOptionsAtom = atom(async (get) => {
  const currentCompany = get(currentCompanyAtom);
  const videoFilters = get(filtersOptionsAtom);

  if (!currentCompany) {
    return {};
  }
  const filters = await getVideoFilters(currentCompany, videoFilters);

  return filters;
});

export const loadableFilterOptionsAtom = loadable(loadFilterOptionsAtom);
