import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';

import { loadableDevicesAtom } from '../../atoms/company';
import Table from '../../elements/Table/Table';
import useColumns from './useColumns';

const DevicesGrid = ({}) => {
  const { t } = useTranslation(['common']);
  const [devices] = useAtom(loadableDevicesAtom);

  const columns = useColumns(t);

  return (
    <Table
      columns={columns}
      isLoading={devices.state === 'loading'}
      data={devices?.data ? devices?.data : []}
      height="h-[35vh]"
    />
  );
};

export default DevicesGrid;
